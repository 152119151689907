/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react'
import styled from 'styled-components';
import { Input, Button } from 'antd';

const { TextArea } = Input;


const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  background: #f7f6f5;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Subtitle = styled.p`
  display: block;
  font-size: 24px;
  width: 100%;
  text-align: center;
`;

const Title = styled.h1`
  display: block;
  font-size: 32px;
  width: 100%;
  text-align: center;
`;

const ClapWrapper = styled.span`
  font-size: 72px;
`

const ThanksWrapper = styled.div`
  font-size: 72px;
`


export default ({onClap, clapped}) => {

  return (
    <Wrapper>
      { 
        clapped ? 
          <Wrapper>
            <ThanksWrapper> You are now officially a designer </ThanksWrapper>
            <iframe title="gif of designer" src="https://giphy.com/embed/521OjNrSVt7qWlrym7" width="480" height="346" frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/giphydating-521OjNrSVt7qWlrym7">via GIPHY</a></p>
            <ThanksWrapper>🎉 We are so proud of you! 🎉</ThanksWrapper>
          </Wrapper>
          :
          <Wrapper>
            <iframe src="https://giphy.com/embed/TzRieLMZZ2YXC" width="480" height="360" frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/funny-designer-deals-TzRieLMZZ2YXC">via GIPHY</a></p>
            <Title>Have you involved a user in your work process?</Title>
            <Subtitle>Tell us about it for a chance to win a dinner date with Halvard and Kristoffer</Subtitle>
              <form onSubmit={onClap} >
                <ClapWrapper role="img" aria-label="hands clapping" alt="clap">
                <TextArea
                  placeholder="Add a short description of how you involved users in your project"
                  autosize={{ minRows: 2, maxRows: 6 }}
                  name="description"
                />
                <Button block size="large" type="primary" htmlType="submit">Submit</Button>
                </ClapWrapper>
              </form> 
          </Wrapper>
      }
    
    </Wrapper>
  );
};
