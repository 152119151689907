import React, { Component } from 'react';
import { CogniteClient } from '@cognite/sdk';

import WelcomePage from './WelcomePage'


const project = 'daitya';

class App extends Component {
  state = {
    assets: null,
    client: null,
    clapped: false,
  }

  async componentDidMount() {
    const client = new CogniteClient({ appId: 'iamdesigner' });
    client.loginWithOAuth({
      project,
    });
    
    const isLogged = await client.authenticate();
    this.setState({ client, isLogged });
  }

  handleClap = async (e) => {
    e.preventDefault()
  
    const startTime = Date.now();
    const endTime = Date.now();
    const description = e.target.description.value;

    const {user} = await this.state.client.login.status()
    const events = [ { description, startTime, endTime, type: 'iamdesigner', subtype: user, assetIds: [6079178817570575]}  ]
    await this.state.client.events.create(events)
    
    this.setState({clapped: true})
  }

  render() {
    const { isLogged, clapped } = this.state
    return (
      <>
        {isLogged ? <WelcomePage clapped={clapped} onClap={this.handleClap}/> : <div> Loading....</div>}
      </>
    )
  }
}

export default App;
